import React, {
  ComponentType,
  FC,
  MouseEvent,
  ReactNode,
  SVGProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classnames from "classnames";
import { componentsVariants, keyboardEvents } from "src/enums";
import Typography, { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { SelectFooter } from "./SelectFooter";
import SelectOptionItem from "./SelectOptionItem";
import { ReactComponent as IconChevron } from "img/common/select/chevron.svg";
import styles from "./Select.scss";

export interface SelectOption {
  label: string;
  value: number | string;
}

type VariantProps = "outlined" | "shader";

interface BaseSelectProps {
  DescriptionIcon?: ComponentType<SVGProps<SVGSVGElement>>;
  containerClassName?: string;
  description?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  label?: ReactNode;
  limitText?: string;
  placeholder?: string;
  variant?: VariantProps;
}

interface MultipleSelectProps extends BaseSelectProps {
  multiple: true;
  onChange: (value: SelectOption[]) => void;
  value: SelectOption[];
}

export interface SingleSelectProps extends BaseSelectProps {
  multiple?: false;
  onChange: (value: SelectOption | undefined) => void;
  value?: SelectOption;
}

export type SelectProps = {
  options: SelectOption[];
} & (MultipleSelectProps | SingleSelectProps);

/**
 * `Select` component.
 * This component is flexible, supporting both single and multiple selections through the `multiple` prop.
 *
 * Props:
 * - `multiple`: Specifies if multiple selections are allowed. When true, `value` expects an array of `SelectOption`. For single selection, this prop is omitted or false.
 * - `value`: The current selection(s). For a single select, this is a `SelectOption` object or undefined. For multiple select, it's an array of `SelectOption` objects.
 * - `onChange`: A callback function invoked when the selection changes, receiving the new value as its argument.
 * - `options`: An array of selectable options. Each option is an object with `label` (display text) and `value` (unique identifier) properties.
 * - `containerClassName`: (Optional) A CSS class name for styling the select component's container.
 * - `DescriptionIcon`: (Optional) A React component for an icon displayed in the select footer, typically used to denote an informational or error state.
 * - `description`: (Optional) Text below the select box for additional information.
 * - `disabled`: (Optional) A boolean indicating if the select component is interactive. When true, the component is non-interactive.
 * - `error`: (Optional) A boolean indicating if the select component is in an error state. Influences styling to reflect error status.
 * - `errorMessage`: (Optional) A message displayed in the footer when the `error` prop is true, providing details about the error.
 * - `limitText`: (Optional) Text displayed on the right side of the footer, useful for indicating selection limits or additional guidelines.
 * - `placeholder`: (Optional) A placeholder text displayed when no option is selected.
 * - `label`: (Optional) A label for the select box, providing context about the options being selected.
 *
 * The component includes accessibility features, such as keyboard navigation, and is designed to be fully responsive.
 * It integrates seamlessly with form libraries and can be used in a variety of UI contexts.
 *
 * @returns JSX.Element - The rendered select component.
 *
 * @example
 * <Select
 *   multiple={true}
 *   value={selectedOptions}
 *   onChange={(newValue) => setSelectedOptions(newValue)}
 *   options={[{ label: 'Option 1', value: '1' }, { label: 'Option 2', value: '2' }]}
 *   limitText="You can select up to 2 options"
 *   description="Select your options"
 * />
 */

export const Select: FC<SelectProps> = ({
  multiple,
  value,
  onChange,
  options,
  containerClassName,
  label,
  error = false,
  errorMessage,
  disabled = false,
  limitText,
  description,
  placeholder = "Select...",
  DescriptionIcon,
  variant = componentsVariants.OUTLINED,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const containerRef = useRef<HTMLDivElement>(null);

  const isShader = variant === componentsVariants.SHADER;

  const selectOption = useCallback(
    (option: SelectOption) => {
      if (multiple) {
        const newValue = value.includes(option)
          ? value.filter((item) => item !== option)
          : [...value, option];

        onChange(newValue);
      } else {
        if (option !== value) {
          onChange(option);
        }
      }
    },
    [multiple, value, onChange]
  );

  const isOptionSelected = (option: SelectOption) =>
    multiple ? value.includes(option) : option.value === value?.value;

  useEffect(() => {
    if (isOpen) {
      setHighlightedIndex(-1);
    }
  }, [isOpen]);

  const onClickSelect = useCallback(() => {
    if (!disabled) {
      setIsOpen((prev) => !prev);
    }
  }, [disabled]);

  const onClickOption =
    (option: SelectOption) => (event: MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();
      selectOption(option);

      if (!multiple) {
        setIsOpen(false);
      }
    };

  const onHandleBlur = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.target !== containerRef.current) {
        return;
      }

      switch (event.code) {
        case keyboardEvents.ENTER:
        case keyboardEvents.SPACE:
          if (!disabled) {
            setIsOpen((prev) => !prev);
          }
          if (isOpen) {
            selectOption(options[highlightedIndex]);
          }
          break;
        case keyboardEvents.ARROW_UP:
        case keyboardEvents.ARROW_DOWN:
          if (!isOpen) {
            setIsOpen(true);
            break;
          }

          // eslint-disable-next-line no-case-declarations
          const newIndex =
            highlightedIndex + (event.code === "ArrowDown" ? 1 : -1);
          if (newIndex >= 0 && newIndex < options.length) {
            setHighlightedIndex(newIndex);

            const optionsList = containerRef.current?.querySelector(
              "ul"
            ) as HTMLUListElement;
            const highlightedOption = optionsList?.children[
              newIndex
            ] as HTMLElement;

            if (optionsList && highlightedOption) {
              const listRect = optionsList.getBoundingClientRect();
              const optionRect = highlightedOption.getBoundingClientRect();

              if (optionRect.bottom > listRect.bottom) {
                optionsList.scrollTop =
                  highlightedOption.offsetTop +
                  highlightedOption.clientHeight -
                  optionsList.offsetHeight;
              } else if (optionRect.top < listRect.top) {
                optionsList.scrollTop = highlightedOption.offsetTop;
              }
            }
          }
          break;
        case keyboardEvents.ESCAPE:
          setIsOpen(false);
          break;
      }
    };

    containerRef.current?.addEventListener("keydown", handler);

    return () => {
      containerRef.current?.removeEventListener("keydown", handler);
    };
  }, [isOpen, highlightedIndex, options, selectOption, disabled]);

  const renderValue = () => {
    if (multiple) {
      return value.length > 0 ? (
        <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH1}>
          {value.map((item) => item.label).join(", ")}
        </Typography>
      ) : (
        <Typography
          type={TYPOGRAPHY_TYPE.PARAGRAPH1}
          className={styles.placeholder}
        >
          {placeholder}
        </Typography>
      );
    }

    return value ? (
      <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH1}>{value.label}</Typography>
    ) : (
      <Typography
        type={TYPOGRAPHY_TYPE.PARAGRAPH1}
        className={styles.placeholder}
      >
        {placeholder}
      </Typography>
    );
  };

  return (
    <div
      className={classnames(containerClassName, {
        [styles.disabled]: disabled,
        [styles.open]: isOpen,
      })}
    >
      {label && (
        <Typography
          type={TYPOGRAPHY_TYPE.PARAGRAPH2}
          className={classnames(styles.label, {
            [styles.shader]: isShader,
          })}
        >
          {label}
        </Typography>
      )}

      <div
        ref={containerRef}
        onBlur={onHandleBlur}
        onClick={onClickSelect}
        tabIndex={0}
        className={classnames(styles.root, "Select-root", {
          [styles.borderError]: error && !disabled,
          [styles.disabled]: disabled,
          [styles.shader]: isShader,
          [styles.open]: isOpen,
          "Select-disabled": disabled,
          "Select-open": isOpen,
          "Select-shader": isShader,
        })}
        {...props}
      >
        <span
          className={classnames(styles.value, "Select-value", {
            [styles.shader]: isShader,
          })}
        >
          {renderValue()}
        </span>
        <IconChevron
          className={classnames(styles.chevron, "Select-chevron", {
            [styles.chevronRevert]: isOpen,
            [styles.shader]: isShader,
          })}
        />
        <ul
          className={classnames(styles.options, "Select-options", {
            [styles.show]: isOpen,
            [styles.shader]: isShader,
          })}
        >
          {options.map((option, index) => (
            <SelectOptionItem
              key={option.value}
              option={option}
              isSelected={isOptionSelected(option)}
              onClick={onClickOption(option)}
              // eslint-disable-next-line react/jsx-no-bind
              onMouseEnter={() => setHighlightedIndex(index)}
              inShader={isShader}
              isHighlighted={index === highlightedIndex}
            />
          ))}
        </ul>
      </div>
      {(description || limitText) && (
        <SelectFooter
          description={description}
          limitText={limitText}
          error={error}
          errorMessage={errorMessage}
          disabled={disabled}
          DescriptionIcon={DescriptionIcon}
          isShader={isShader}
        />
      )}
    </div>
  );
};

Select.displayName = "Select";
