import React, { PropsWithChildren, ReactNode, forwardRef } from "react";
import classnames from "classnames";
import Typography from "ui/common/typography/Typography";
import { ParagraphTypographyType } from "ui/common/typography/types";
import styles from "./TextField.scss";

export interface FieldProps {
  className?: string;
  disabled?: boolean;
  focused?: boolean;
  helperText?: ReactNode;
  id?: string;
  isError?: boolean;
  label?: ReactNode;
  limitText?: string;
  onClick?: VoidFunction;
  prefix?: ReactNode;
}

export const Field = forwardRef<HTMLDivElement, PropsWithChildren<FieldProps>>(
  (props, ref) => {
    const {
      id,
      children,
      className,
      label,
      prefix,
      helperText,
      limitText,
      isError,
      disabled,
      focused,
      onClick,
    } = props;

    return (
      <label
        id={id}
        className={classnames(
          styles.root,
          {
            [styles.error]: isError,
            [styles.disabled]: disabled,
            [styles.focused]: focused,
          },
          className
        )}
        onClick={onClick}
      >
        {label && (
          <Typography
            type={ParagraphTypographyType.PARAGRAPH2}
            className={styles.label}
          >
            {label}
          </Typography>
        )}

        <Typography
          as="div"
          type={ParagraphTypographyType.PARAGRAPH1}
          className={styles.input}
          ref={ref}
        >
          {prefix && <div className={styles.prefix}>{prefix}</div>}

          {children}
        </Typography>

        {!!(helperText || limitText) && (
          <div className={styles.footer}>
            {!!helperText && (
              <Typography
                type={ParagraphTypographyType.PARAGRAPH4}
                className={styles.description}
              >
                {helperText}
              </Typography>
            )}

            {!!limitText && (
              <Typography
                as="div"
                type={ParagraphTypographyType.PARAGRAPH4}
                className={styles.limitText}
              >
                {limitText}
              </Typography>
            )}
          </div>
        )}
      </label>
    );
  }
);

Field.displayName = "Field";
