import React, {
  Suspense,
  memo,
  useCallback,
  useContext,
  useState,
} from "react";
import { useSelector } from "react-redux";
import type { Emoji, EmojiMartData } from "@emoji-mart/data";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import { Breakpoints } from "src/enums";
import { HTMLButtonProps, Nullable } from "src/types/common";
import lazyWithPageReload from "src/utils/lazyWithPageReload";
import { useEffectOnce } from "src/utils/miniReactUse";
import { deviceInfoSelectors } from "state/selectors";
import { ThemeContext } from "ui/Theme";
import Button, { ButtonSize, ButtonVariant } from "ui/common/button/Button";
import Dropdown from "ui/common/dropdown/Dropdown";
import EmojiContext from "ui/common/emoji/EmojiContext";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import useExclusiveClickHandler from "ui/hooks/useExclusiveClickHandler";
import { ReactComponent as EmojiIcon } from "img/ic_emoji.svg";
import styles from "./EmojiPickerButton.scss";
import transition from "./EmojiPickerButtonTransition.scss";

const EmojiPicker = lazyWithPageReload(() =>
  import(
    /* webpackChunkName: "emojiPicker" */
    /* webpackPrefetch: true */
    "@emoji-mart/react"
  ).then((picker) => picker)
);

interface EmojiPickerButtonProps extends HTMLButtonProps {
  className?: string;
  disabled?: boolean;
  onPickEmoji: (data: Emoji) => void;
  onPickerVisibilityChange?: (visible: boolean) => void;
  pickerWrapperClassName?: string;
  theme?: "dark" | "light";
}

const EmojiPickerButton: React.FC<EmojiPickerButtonProps> = ({
  className,
  pickerWrapperClassName,
  onPickEmoji,
  onPickerVisibilityChange = emptyFunction,
  theme,
  ...rest
}) => {
  const { dark } = useContext(ThemeContext);
  const [data, setData] = useState<Nullable<EmojiMartData>>(null);
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
  const locale = useSelector(deviceInfoSelectors.getDeviceLocale);
  const toggleEmojiPickerVisible = useCallback(() => {
    setEmojiPickerVisible((prev) => {
      onPickerVisibilityChange(!prev);

      return !prev;
    });
  }, [onPickerVisibilityChange]);
  const intercept = useExclusiveClickHandler();
  const { shouldConvertEmojis } = useContext(EmojiContext);
  const handlePickEmoji = useCallback(
    (emoji: Emoji) => {
      onPickEmoji(emoji);
      onPickerVisibilityChange(false);
      setEmojiPickerVisible(false);
    },
    [onPickEmoji, onPickerVisibilityChange]
  );

  useEffectOnce(() => {
    let mounted = true;
    import(
      /* webpackChunkName: "emojiPicker" */ "@emoji-mart/data/sets/14/twitter.json"
    ).then(({ default: data }) => {
      if (mounted) {
        setData(data);
      }
    });

    return () => {
      mounted = false;
    };
  });

  return (
    <>
      <Button
        size={ButtonSize.MEDIUM_32}
        variant={ButtonVariant.ICON_ONLY}
        className={classnames(className, {
          [styles.active]: emojiPickerVisible,
        })}
        onClick={toggleEmojiPickerVisible}
        disabled={!data}
        {...rest}
      >
        <EmojiIcon />
      </Button>
      <Suspense fallback={null}>
        <Dropdown
          mountOnEnter={false}
          unmountOnExit={false}
          isVisible={emojiPickerVisible}
          onClose={toggleEmojiPickerVisible}
          transition={transition}
        >
          <div
            // Intercepts events dropdown listens to prevent closing when clicked/touched inside
            onClick={intercept}
            onTouchStart={intercept}
            className={classnames(styles.pickerWrapper, pickerWrapperClassName)}
          >
            {data && (
              <EmojiPicker
                locale={locale}
                theme={theme || (dark ? "dark" : "light")}
                set={shouldConvertEmojis ? "twitter" : "native"}
                data={data}
                onEmojiSelect={handlePickEmoji}
                previewPosition="none"
                skinTonePosition="search"
              />
            )}
          </div>
        </Dropdown>
      </Suspense>
    </>
  );
};

const EmojiPickerWrapper: React.FC<EmojiPickerButtonProps> = (props) => {
  const isDesktop = useBreakpointPrecise() === Breakpoints.DESKTOP;

  return isDesktop ? <EmojiPickerButton {...props} /> : null;
};

export default memo(EmojiPickerWrapper);
